@import '../../mixins';

.blog_primary {
  & .container {
    @include mediaMobile {
      gap: rem(25);
    }
  }

  & .blog {
    &__content {
      @include mediaMobile {
        margin-top: rem(5);
      }
    }

    &__wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      column-gap: var(--grid-column-gap);
      row-gap: var(--grid-row-gap);

      @include media(#{rem(990)}) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include mediaMobile {
        display: flex;
        flex-direction: column;
        gap: 0;
      }
    }
  }

  & .news-card {
    width: 100%;

    overflow: visible;

    @include mediaMobile {
      border-bottom: solid 1px var(--stroke-dark-secondary);
    }


    &:nth-child(1),
    &:nth-child(2) {
      position: relative;
      padding: rem(40) 0;

      @include mediaBigDesktop {
        padding: big(40) 0;
      }

      @include media(#{rem(990)}) {
        padding: rem(30) 0;
      }

      @include mediaMobile {
        padding: rem(15) 0;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;

        width: calc(100% + (var(--grid-column-gap) / 2));
        height: rem(1);

        background-color: var(--stroke-dark-secondary);

        @include mediaBigDesktop {
          height: big(1);
        }

        @include mediaMobile {
          content: unset;
        }
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }

    &:nth-child(1) {

      @include mediaMobile {
        border-top: solid 1px var(--stroke-dark-secondary);
      }

      &::before,
      &::after {
        left: 0;
      }
    }

    &:nth-child(2) {

      &::before,
      &::after {
        right: 0;
      }
    }

    &:nth-child(3),
    &:nth-child(4) {
      @include mediaTablet {
        padding-bottom: rem(10);
      }

      @include mediaMobile {
        display: none;
      }
    }

    &:last-child {
      grid-column: 3/4;
      grid-row: 1/3;

      @include media(#{rem(990)}) {
        grid-column: 1/3;
        grid-row: 3;
      }

      @include mediaMobile {
        display: none;
      }

      & .news-card {
        &__image {

          & img,
          & picture {
            height: rem(283);

            @include mediaBigDesktop {
              height: big(283);
            }

            @include mediaTablet {
              height: rem(238);
            }
          }
        }
      }
    }

    &__content {
      @include mediaMobile {
        padding-right: 0;
      }
    }
  }
}
